<template>
    <div v-if="ready" class="fill-height">
        <subpage-top-bar>
            <template #default>
                Сборка приложений
            </template>
            <template #right>
                <v-btn
                    dark
                    class="mr-2"
                    outlined
                    @click="toggleIsMergedDECVisibility"
                    title="Отображение 'входящих в состав' ЦОК"
                >
                    <span class="mr-1">{{showIsMergedDEC ? 'Вкл' : 'Выкл'}}</span>
                    <v-divider vertical class="mx-2" />
                    <v-icon>mdi-network-outline</v-icon>
                </v-btn>
            </template>
        </subpage-top-bar>
       
        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                v-if="!errors"
                justify="start"
                align="stretch"
                class="fill-height overflow-hidden"
            >
                <v-col :cols="12" class="fill-height overflow-auto">
                    <v-stepper
                        v-model="buildOptions.step"
                        vertical
                        elevation="0"
                    >
                        <v-stepper-step
                            step="1"
                            :complete="buildOptions.step > 1"
                        >
                            Укажите ЦОК и платформы, для которых нужно собрать приложения
                        </v-stepper-step>
                        <div class="v-stepper__content" :class="{unavailable: buildOptions.step > 1}">
                            <v-data-table
                                :headers="headers"
                                :items="filteredItems"
                                hide-default-footer
                                :items-per-page="99999"
                                class="elevation-1"
                            >
                                <template v-slot:[`header.windows`]="{ header  }">
                                    <a @click.prevent.stop="selectAll('windows')">
                                        <span v-html="header.text"></span>
                                    </a>
                                </template>
                                <template v-slot:[`header.linux`]="{ header  }">
                                    <a @click.prevent.stop="selectAll('linux')">
                                        <span v-html="header.text"></span>
                                    </a>
                                </template>
                                <template v-slot:[`header.linux_rpm`]="{ header  }">
                                    <a @click.prevent.stop="selectAll('linux_rpm')">
                                        <span v-html="header.text"></span>
                                    </a>
                                </template>

                                <template v-slot:[`item.courseName`]="{ item }">
                                    <div class="d-flex flex-row flex-nowrap jusitufy-space-between align-center">
                                        <div>{{ item.courseName }}</div>
                                        <v-spacer />
                                        <div class="d-flex flex-row flex-nowrap jusitufy-space-between align-center">
                                            <v-icon 
                                                class="mx-1" 
                                                :class="{'text--disabled': _.isNil(item.appWindowsUrl)}"
                                                :color="_.isNil(item.appWindowsUrl) ? null : 'blue darken-1'"
                                            >
                                                mdi-microsoft-windows
                                            </v-icon>
                                            <v-icon 
                                                class="mx-1" 
                                                :class="{'text--disabled': _.isNil(item.appLinuxUrl)}"
                                                :color="_.isNil(item.appLinuxUrl) ? null : 'red darken-3'"
                                            >
                                                mdi-debian
                                            </v-icon>
                                            <v-icon 
                                                class="mx-1" 
                                                :class="{'text--disabled': _.isNil(item.appLinuxRpmUrl)}"
                                                :color="_.isNil(item.appLinuxRpmUrl) ? null : 'red'"
                                            >
                                                mdi-redhat
                                            </v-icon>
                                        </div>
                                    </div>
                                    <v-icon v-if="item.merge">mdi-merge</v-icon>
                                </template>
                                <template v-slot:[`item.merge`]="{ item }">
                                    <v-icon v-if="item.merge">mdi-merge</v-icon>
                                </template>
                                <template v-slot:[`item.isMerged`]="{ item }">
                                    <v-icon v-if="item.isMerged">mdi-network-outline</v-icon>
                                </template>
                                <template v-slot:[`item.windows`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.windows"
                                    ></v-simple-checkbox>
                                </template>
                                <template v-slot:[`item.linux`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.linux"
                                    ></v-simple-checkbox>
                                </template>
                                <template v-slot:[`item.linux_rpm`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.linux_rpm"
                                    ></v-simple-checkbox>
                                </template>
                            </v-data-table>
                            <v-divider />
                            <v-btn
                                v-if="buildOptions.step === 1"
                                color="primary"
                                :disabled="!allowBuild"
                                @click.prevent.stop="build"
                            >
                                Начать сборку
                            </v-btn>
                        </div>

                        <v-stepper-step
                            step="2"
                            :complete="buildOptions.step > 2"
                        >
                            Ожидайте окончания сборки
                            <small>Можно покинкуть страницу и вернуться позже.</small>
                        </v-stepper-step>
                        <div class="v-stepper__content" v-if="buildOptions.step === 2">
                            <v-progress-linear
                                color="primary"
                                buffer-value="0"
                                :value="progressPercent"
                                stream
                            ></v-progress-linear>
                            <br />
                            <div class="text-caption"><strong>Выполнено:</strong> {{ buildOptions.progress.complete }} из {{ buildOptions.progress.total }}</div>
                        </div>
                        
                        <v-stepper-step
                            :complete="buildOptions.step > 3"
                            step="3"
                        >
                            Готово!
                        </v-stepper-step>
                        <div class="v-stepper__content" v-if="buildOptions.step === 3">
                            <v-alert type="success">
                                <p><strong>Сборка успешно выполнена!</strong></p>
                            </v-alert>

                            <v-btn
                                color="primary"
                                @click.prevent.stop="reset"
                            >
                                Начать новую сборку
                            </v-btn>
                        </div>

                    </v-stepper>
                </v-col>
            </v-row>

            <div v-else class="mx-auto">
                <v-alert type="error">
                    {{ errors || 'Произошла ошибка на сервере. Обратитесь за помощью к администратору.' }}
                </v-alert>

                <div
                    v-if="allowEdit"
                    class="w-100 d-flex justify-center align-center"
                >
                    <v-btn class="mx-auto" @click="resetError">
                        <v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
                        Отмена
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
    <!-- Loading icon -->
    <div
        v-else
        class="
            wv-100
            vh-100
            fill-height
            d-flex
            flex-row
            justify-center
            align-center
        "
    >
        <v-progress-circular
            :size="70"
            :width="7"
            color="purple darken-3"
            indeterminate
        />
    </div>
</template>
<script>
import SubpageTopBar from '@/components/template/SubpageTopBar'
import { mapGetters } from "vuex";

const PROGRESS_CHECK_INTERVAL = 10000;

export default {
    name: "Main",
    components: {
        SubpageTopBar
    },
    data () {
        return {
            ready: false,
            errors: null,
            headers: [
                { text: "ЦОК", value: "courseName", sortable: false },
                { text: "Объединённый", value: "merge", width: 1, align: "center", sortable: false },
                { text: "В составе", value: "isMerged", width: '100px', align: "center", sortable: false },
                { text: "Windows", value: "windows", width: 1, align: "center", sortable: false },
                { text: "Linux (deb)", value: "linux", width: 1, align: "center", sortable: false },
                { text: "Linux (rpm)", value: "linux_rpm", width: 1, align: "center", sortable: false }
            ],
            showIsMergedDEC: false,
            buildOptions: {
                step: 1,
                items: [],
                progress: {
                    total: 0,
                    complete: 0
                }
            }
        };
    },
    computed: {
        ...mapGetters("user", ["allowEdit"]),
        allowBuild() {
            return _.size(this.getSelectedItems()) > 0;
        },
        progressPercent () {
            return this.buildOptions.progress.total > 0 ? Math.ceil((this.buildOptions.progress.complete / this.buildOptions.progress.total) * 100) : 0;
        },
        mergedItems () {
            const items = this.$store.state.app?.eduCourses || []
            let itemsToHide = [];
            items?.map(_item => {
                if (_item.merge?.length) {
                    itemsToHide = itemsToHide.concat(_item.merge);
                }
            })
            return itemsToHide
        },
        filteredItems () {
            if (this.showIsMergedDEC)
                return this.buildOptions.items
            else
                return this.buildOptions.items.filter(item => !item.isMerged)
        }
    },
    methods: {
        async fetch() {
            return this.$store.dispatch('app/fetch', {
                filter: ['AND', ['=', 'courseTypeId', 0],['=', 'version', 'ministry']],
                fields: 'id,externalId,courseName,courseTypeId,merge,appWindowsUrl,appLinuxUrl,appLinuxRpmUrl'
            });
        },
        async build() {
            // Set options
            this.buildOptions.step = 2;
            this.buildOptions.items = this.getSelectedItems();
            this.buildOptions.progress.total = _.sum(_.map(this.buildOptions.items, o => {
                return _.size(_.compact([o.windows, o.linux, o.linux_rpm]));
            }));
            // Init build process
            await this.$store.dispatch('app/buildApps', this.buildOptions);
            // Start status check daemon
            const progressChecker = setInterval(async () => {
                const { data } = await this.$store.dispatch('app/buildAppsCheck');
                this.$set(this, 'buildOptions', data.options);
                if(this.buildOptions.step > 2)
                    clearInterval(progressChecker);
            }, PROGRESS_CHECK_INTERVAL)
        },
        async reset() {
            await this.fetch();
            this.buildOptions = {
                step: 1,
                items: this.getAllItems(),
                progress: {
                    total: 0,
                    complete: 0
                }
            }
        },
        resetError () {
            this.errors = null;
        },
        async updateBuildOptions() {
            const { data } = await this.$store.dispatch('app/buildAppsCheck');
            if(!_.isNil(data))
                this.$set(this, 'buildOptions', data.options);
        },
        createProgressChecker() {
            const progressChecker = setInterval(async () => {
                await this.updateBuildOptions();
                if(this.buildOptions.step > 2)
                    clearInterval(progressChecker);
            }, PROGRESS_CHECK_INTERVAL);
        },
        getAllItems() {
            return _.map(_.filter(this.$store.state.app.eduCourses, {courseTypeId: 0}), o =>{
                return {
                    externalId: o.externalId,
                    courseName: o.courseName,
                    isMerged: this.mergedItems.includes(o.externalId),
                    merge: !!o.merge,
                    appWindowsUrl: o.appWindowsUrl,
                    appLinuxUrl: o.appLinuxUrl,
                    appLinuxRpmUrl: o.appLinuxRpmUrl,
                    windows: false,
                    linux: false,
                    linux_rpm: false
                }
            });
        },
        getSelectedItems() {
            return  _.filter(this.buildOptions.items, o => {
                return o.windows || o.linux || o.linux_rpm;
            });
        },
        toggleIsMergedDECVisibility () {
            this.showIsMergedDEC = !this.showIsMergedDEC
        },
        selectAll(os) {
            const value = _.size(this.buildOptions.items) === _.size(_.filter(this.buildOptions.items, {[os]: true}));
            this.buildOptions.items = _.map(this.buildOptions.items, o => {
                o[os] = !value;
                return o;
            });
        }
    },
    async created() {
        try {
            if (!this.allowEdit) {
                throw new Error('У вас недостаточно прав для доступа к этому разделу.');
            }
            // Fetch data 
            await this.fetch();
            this.buildOptions.items = this.getAllItems();

            // Fetch last build
            await this.updateBuildOptions();        
            if(this.buildOptions.step === 2)
                this.createProgressChecker();
        } catch (e) {
            console.error(e);
            this.errors = e.message;
        } finally {
            this.ready = true;
        }
    }
};
</script>